@import '../../../../../style/utilities.scss';

.advertise {
    max-height: 80vh;
    height: fit-content;
    max-width: 229px;
    min-width: 229px;
    // width: 229px;
    background: $off-white;
    // border: 2px solid $white;
    // box-shadow: 0px 0px 18.9097px 1.89097px rgba(0, 0, 0, 0.3);
    border: 1.89px solid $white;
    box-shadow: 0px 0px 18.909656524658203px 1.8909657001495361px rgba(0, 0, 0, 0.3);
    border-radius: 9.45483px;
    gap: 11px;
    overflow: hidden;

    .banner {
        cursor: pointer;
    }
}

.ad-feed {
    gap: 7px;

    p {
        font-weight: 500;
        font-size: 14px;
        line-height: 13px;
        color: $repeated-text-color;
    }
}

.ad-feed-container {

    max-height: 330px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    row-gap: 9px;

    &::-webkit-scrollbar {
        width: 6.62px;
    }

    &::-webkit-scrollbar-track {

        background: $white;
        border-radius: 94.5483px;
        position: relative;
    }

    &::-webkit-scrollbar-thumb {
        width: 4.73px;
        background: #315EFB;
        border-radius: 94.5483px;
    }

}

.select-member {
    box-shadow: 16px 16px 7.49px -9.86px rgba(0, 0, 0, 0.35);
    border-radius: 3px;

    label {
        border-radius: 3px;
        cursor: pointer;
    }

    input[type=checkbox]:checked~label {
        // color: $dashboard-primary-theme-text !important;
        border: 1px solid $repeated-text-color;

    }
}

.ad-card {
    height: 95px;
    width: 105px;
    border-radius: 3px;
    border: 0.507246px solid $repeated-text-color;
    flex-direction: column;

    .rating-div {
        gap: 3px;
    }

    .ad-card-img {
        width: 45px;
        height: 45px;

        img {
            height: 100%;
            width: 100%;
        }
    }

    .member_name {
        font-family: 'Poppins';
        font-size: 10px;
        line-height: 10px;
        color: $dashboard-primary-theme-text;
    }

    .rating {
        font-size: 1rem !important;
    }
}



.ad-cardUser {
    width: 93.6px;
    height: 97.85px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $dashboard-primary-theme-text;

    .ad-name {
        height: 12.67px;
        background: rgba(0, 21, 128, 0.8);
        bottom: 26.66px;

        p {
            font-size: 8px;
            line-height: 8px;
            color: $white;
        }
    }

    .ad-title {
        bottom: 10.27px;
        height: 12.67px;
        background: rgba(255, 255, 255, 0.5);

        p {
            font-size: 6px;
            line-height: 8px;
            color: #000000;
        }
    }
}