@import "../../../../../../style/_utilities.scss";

.skeleton {
  background-color: #dddbdd;
  position: relative;

  animation: skeleton-loading 1s linear infinite alternate;
  // &::after {
  //     position: absolute;
  //     top: 0;
  //     right: 0;
  //     bottom: 0;
  //     left: 0;
  //     transform: translateX(-100%);
  //     background-image: linear-gradient(90deg,
  //             rgba(#fff, 0) 0,
  //             rgba(#fff, 0.2) 20%,
  //             rgba(#fff, 0.5) 60%,
  //             rgba(#fff, 0));
  //     animation: shimmer 2s infinite;
  //     content: '';
  // }

  // @keyframes shimmer {
  //     100% {
  //         transform: translateX(100%);
  //     }
  // }
  @keyframes skeleton-loading {
    0% {
      background-color: rgba(#fff, 0.2) 20%;
      // background-color: hsl(200, 81%, 89%);
    }

    100% {
      background-color: rgba(#fff, 0.5) 60%;
      // background-color: hsl(202, 24%, 93%);
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 81%, 89%);
  }

  100% {
    background-color: hsl(202, 24%, 93%);
  }
}

.proprty-head {
  color: $dashboard-primary-theme-text;
  font-weight: 500;
  font-size: 20px;
}

.property-top {
  .blue__btn {
    padding: 14px;
  }

  .row,
  .col {
    padding: 0;
    margin: 0;
  }

  .save {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      font-size: 19px;
    }
  }
}

.input-group {
  border: 2px solid;

  border-image-source: linear-gradient(
    90deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 217.5%
  );
  border-radius: 10px;
  box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 217.5%),
    linear-gradient(
      157.69deg,
      rgba(255, 255, 255, 0.1) 6.02%,
      rgba(255, 255, 255, 0) 95.12%
    );
}

.property-Img {
  .property-main-img {
    // aspect-ratio: 1.7/1;
    max-height: 300px;
    min-height: 300px;
    border: 2px solid $dashboard-primary-theme-text;
    border-radius: 4px;
    overflow: hidden;
  }

  .property-sub-img {
    overflow-x: scroll;
    // max-width: 100%;
    max-width: 450px;

    .sub-img {
      width: 110px;
      height: 60px;
      aspect-ratio: 1.6/1;
      border: 2px solid $dashboard-primary-theme-text;
      border-radius: 4px;

      img {
        border-radius: 4px;
      }
    }
  }

  .arrow {
    width: 20px;
    height: 20px;
    background: #fff;

    svg {
      font-size: 15px;
    }
  }
}

.prop-text-info {
  .property-name {
    font-size: 16px;
    line-height: 12px;
    color: $dashboard-primary-theme-text;

    span {
      font-size: 16px;
      margin-left: 7px;
      color: $lite-black;
    }
  }

  .property-price {
    color: $lite-black;

    .price {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    .label {
      font-size: 12px;
      // line-height: 18px;
    }
  }
}

.team-member {
  max-width: 289px;
  overflow: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin-left: 27px;
  margin-right: 27px;
  gap: 15px;
  padding-bottom: 30px;
}

.prop-loaction {
  min-height: 360px !important;
  max-height: 360px !important;
  overflow: hidden !important;
  border: 2px solid $dashboard-primary-theme-text !important;
  border-radius: 4px !important;
}

.TeamscrollRef {
  .arrow {
    top: 50%;
    transform: translateY(calc(-50% - 15px));
  }
}
