@import "../../../../style/_utilities.scss";

.selPersonCardWidth {
    flex-shrink: 0;
    width: 187px;

    .checkbox {
        position: relative;
        overflow: hidden;
        line-height: 16px;
    }

    .checkbox__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
    }

    .checkbox__inner {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $dashboard-primary-theme-text;
    }

    .checkbox__input:checked+.checkbox__inner {
        background-image: url("./images/inputcheck.svg");
        background-color: $dashboard-primary-theme-text;
    }

    .card-body {
        img {
            width: 86px;
            height: 91px;
        }

        p {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 15.3877px;
            line-height: 15px;
            color: $dashboard-primary-theme-text;
            padding-bottom: 11px;
        }

        .star_icon_yellow {
            padding-bottom: 30px;
            display: flex;
            gap: 9px;

            svg {
                color: #ffcf3d;
                font-size: 15px;
            }
        }

        .card_center {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}