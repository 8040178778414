@import '../.././.././.././.././style/_utilities.scss';

.personal_info_pading {
    padding: 41px 44px 44px 44px;

    .radio_button_text {
        display: flex;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #585656;
        margin-bottom: 18px;

        .red-star {
            color: red !important;
            font-size: 20px;
        }

    }

    // input[type='radio'] {
    //     -webkit-appearance: none;
    //     width: 15px;
    //     height: 15px;
    //     border-radius: 50%;
    //     outline: none;
    //     box-shadow: 0 0 0 2px #4E62B1;
    // }

    // input[type='radio']:before {
    //     content: '';
    //     display: block;
    //     width: 100%;
    //     height: 100%;
    //     margin: 20% auto;
    //     border-radius: 50%;
    // }

    // input[type='radio']:checked:before {
    //     background: #4E62B1;
    // }



    // input section styles
    .main_inputpading {
        padding-top: 29px;


        .main_input {
            border-bottom: 0.676897px solid #546781;
            padding-bottom: 4.7px;
            // margin-bottom: 33px;

            .input_name {
                padding-right: 8.75px;
                border-right: 0.676897px solid #546781;
                width: 300px;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;

                .red-star {
                    color: red !important;
                    font-size: 20px;

                }
            }

            .input-sec {
                width: 100%;
            }

            .form-control:focus {
                box-shadow: none;
            }

            input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;
                border-radius: 0px;
                background: transparent;
            }

        }

        //======= column-3========

        .verify-btn {
            background: linear-gradient(0deg, #315efb, #315efb), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            border: 2px solid transparent;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            border-radius: 20px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #FFFFFF;
            width: 127px;
            height: 40px;
            margin-bottom: 43px;
            &__success {
                background: linear-gradient(0deg, #2FC40B, #2FC40B), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            }
            &__rejected {
                background: linear-gradient(0deg, #ff3838, #ff3838), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            }
        }

        .Save-btn {
            // background:  linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            border: 2px solid #315EFB;
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            border-radius: 2px;
            width: 127px;
            height: 40px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #315EFB;

            &:hover {
                box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
                border-radius: 2px;
                background: #315EFB;
            }
        }
    }
}

.licence-confirmPopup {
    font-size: 18px;
}