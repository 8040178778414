@import url(../../../style/_utilities.scss);

.AllSelPerson_page {
  //   .main_selperson_tab ul{
  width: 100%;
  //   }
  .save__btn {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #315efb;
    padding: 14px 65px;
  }

  .main_selperson_tab {
    display: flex;
    flex-direction: column;
    ul {
      width: 50%;
    }
    .tab-content {
      flex: 1;
      display: flex;

      .tab-pane.show {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    .nav-tabs .nav-link {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      /* or 100% */

      text-transform: uppercase;

      color: #585656;
    }
    .nav-tabs {
      border: 0px;
      .nav-link {
        border: 0px;
      }
    }
    .nav-tabs .nav-link.active {
      // outline: none;
      // font-family: "Poppins";
      // font-style: normal;
      // font-weight: 500;
      // font-size: 14px;
      // line-height: 12px;
      // text-transform: uppercase;
      // text-transform: uppercase;
      outline: none;
      color: #315efb;
      border-bottom: 4px solid #315efb;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
}
.card_gap {
  column-gap: 39px;
  row-gap: 24px;
}
