@import "../../../../../style/_utilities.scss";

.cards_property {
    min-width: 112px;
    // box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);

    .card {
        .cardBody {
            padding: 13px 5px;
            box-sizing: border-box;
            // width: 135px;

            img {
                height: 35px;
                aspect-ratio: 1/1;
                background: $dashboard-primary-theme-text;
                margin-bottom: 5px;
                object-fit: cover;
            }

            .Tom_text p {
                font-size: 12px;
                line-height: 10px;
                text-align: center;
                margin-bottom: 2.13px !important;
                color: $dashboard-primary-theme-text;
            }

            .text_Advert {
                p {
                    font-size: 9px;
                    color: #000;
                    line-height: 10px;
                    text-align: center;
                    margin-bottom: 2.63px !important;
                }
            }

            // .text_Real {
            //     font-weight: 300;
            //     font-size: 8px;
            //     color: $lite-black;
            //     margin-bottom: 2px !important;
            // }

            .Star_icon {
                svg {
                    font-size: 10px;
                }
            }
        }
    }
}

.property-team-member {
    max-width: 86px;
    width: 100%;
    //height: 108px;
    box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
    border: 0.5px solid $dashboard-primary-theme-text;
    border-radius: 2px;

    .prof-img {
        width: 37px;
        aspect-ratio: 1/1;
        overflow: hidden;
        margin-bottom: 4px;
        .MuiAvatar-root {
            border-radius: initial;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .name {
        font-size: 10px;
        line-height: 12px;
        color: rgba(78, 98, 177, 1);
    }

    .user_type {
        color: #000 !important;
        font-weight: 500;
        font-size: 10px;
    }

    .license_title {
        font-size: 10px;
        line-height: 10px;
    }

    .Star_icon {
        svg {
            font-size: 13px;
        }
    }


}