@import '../.././.././.././.././style/_utilities.scss';


.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_team_section {
    padding: 40px 43px 0px 43px;

    .creat_text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #315efb;
        margin-bottom: 43px;
        display: block;
    }

    .radio_buttonpad {
        padding-bottom: 28px;

        .yes_no {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #585656;
            margin-left: 13px;
            margin-right: 14px;
        }
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4.7px;

        .input_name {
            padding-right: 8.75px;
            border-right: 0.676897px solid #546781;
            width: 180px;
            display: flex;
            // justify-content: flex-end;
            align-items: flex-end;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.4px;

            .red-star {
                color: red !important;
                font-size: 20px;
            }
        }

        .input-sec {
            width: 100%;
        }

        .form-control:focus {
            box-shadow: none;
        }

        input {
            font-family: "Poppins";
            font-size: 13px;
            color: #585656;
            border-radius: 0px;
            background: transparent;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .Save_button {
        background: linear-gradient(157.69deg,
                rgba(255, 255, 255, 0.1) 6.02%,
                rgba(255, 255, 255, 0) 95.12%);
        border: 2px solid #315efb;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 20px;
        padding: 0.875rem 3rem;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 12px;
        color: #315efb;

        &:hover {
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            color: #ffffff;
            border: 2px solid #ffffff;
            background: #315efb;
        }
    }

    .invite_teambtn {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 11.5729px;
        line-height: 12px;
        color: #315efb;
        background: linear-gradient(157.69deg,
                rgba(255, 255, 255, 0.1) 6.02%,
                rgba(255, 255, 255, 0) 95.12%);
        border: 1.92881px solid #315efb;
        box-shadow: -5.34282px 6.77978px 21.3616px -11.321px rgba(34, 34, 34, 0.35);
        border-radius: 1.92881px;
        padding: 13.5px 65.5px;
        margin-bottom: 34px;

        &:hover {
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            color: #ffffff;
            border: 2px solid #ffffff;
            border-radius: 2px;
            background: #315efb;
        }
    }

    .input_namES th {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 11.5729px;
        line-height: 12px;
        color: #315efb;
        row-gap: 45px;
        padding-bottom: 29px;
    }

    .user_tame_table tr th {
        padding-left: 0;
        border: 0px;
    }

    .user_tame_table {
        .team-form-input {
            border: 0px;
            width: 100%;
            font-family: "Poppins";
            font-size: 11.5729px;
            line-height: 12px;
            color: $lite-black;
        }
    }

    .user_tame_table tbody tr td {
        padding-left: 0;
        border: 0px;
    }

    // .user_table .table>:not(:first-child) {
    // border-top: 0px solid currentColor !important;
    // }
    .user_tame_table tbody tr td .border-line {
        border-bottom: 0.652804px solid #546781;
        width: 120px;
        // padding-top: 10px;
    }

    div {
        border: 0px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 11.5729px;
        line-height: 12px;
        color: #585656;
        border-bottom: 0px;
    }

    .user_tame_table tbody tr td .deleteimg {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: $dashboard-primary-theme-text;
            font-size: 20px;
            cursor: pointer;
        }
    }

    img {
        width: 20px;
        display: inline-block;
    }

    .main_user_table .table> :not(:first-child) {
        border-top: 0px solid currentColor !important;
    }

    .main_user_table {
        max-height: 300px;
        overflow-y: scroll;
        scroll-behavior: smooth;
    }
}

.belete_maxwidth {
    max-width: 70px;
}


.invite_modals {
    .modal-content {
        border: 1px solid #315EFB;
        box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
        backdrop-filter: blur(20px);
        border-radius: 4px;
        padding-left: 45px;
        padding-right: 45px;
    }
}

.invite_body {


    .forgot_title {
        margin-top: 18px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #315EFB;

        img {
            height: 20px;
            width: 20px;
        }
    }

    .forgot_small_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #585656;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4px;
        margin-bottom: 20px;

        .input_namepad {
            padding-right: 8.75px;
            border-right: 0.676897px solid #546781;
            width: 140px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.5px;

            .red-star {
                color: red !important;
                font-size: 20px;

            }
        }

        .input-sec {
            width: 100%;
            display: flex;

            .form-control:focus {
                box-shadow: none;
            }

            input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;
                border-radius: 0px;
                background: transparent;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

    .submit_btn {
        background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        border: 2px solid #315EFB;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 2px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #315EFB;
        height: 40px;
        width: 127px;
        margin-top: 12px;

        &:hover {
            box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
            color: #ffffff;
            border: 2px solid #ffffff;
            border-radius: 2px;
            background: #315efb;
        }
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #585656;
        text-decoration: none;

        span {
            color: #315EFB;

        }
    }

    .btn-close {
        box-sizing: content-box;
        width: 1em;
        height: 1em;
        padding: 0.25em 0.25em;
        color: #000;
        background-image: url(./images/close.svg);
        cursor: pointer;
        border: 0;
        border-radius: 0.375rem;
        opacity: .5;

    }

}