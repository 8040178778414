@import "../.././.././.././.././style/_utilities.scss";

.personal_page {
  padding: 35px 52px 52px 35px;
  max-height: 80vh;
  overflow-y: scroll;
  scroll-behavior: smooth;

  .profile_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #315efb;
    padding-bottom: 13px;
  }

  .img_widht {
    max-height: 195px;
    background: red;
    height: 200px;

    .profile_pic {
      max-height: 83px;
      max-width: 89px;
      background: black;
    }
  }

  .large_image {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 195px;
  }

  .small_img {
    position: absolute;
    bottom: -36px;
    left: 56px;
    // height: 83px;
    aspect-ratio: 1/1;
    width: 89px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid gray;

    .profile_piC {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  .personal_bgimage {
    .large_image-upload>input {
      display: none;
    }

    .large_image-upload {
      .bg_white {
        border-radius: 50%;
        background: #fdfdfd;
        height: 30px;
        width: 30px;
      }
    }

    .upload_image {
      position: absolute;
      top: 12px;
      right: 10px;
      cursor: pointer;
    }

    .small_image-upload>input {
      display: none;
    }

    .small_image-upload {
      position: absolute;
      bottom: 9px;
      height: 13px;
      right: 14px;
    }

    .small_upload_image {
      position: absolute;
      cursor: pointer;
      width: 22px;
      right: -8px;
      bottom: 1px;

      .bg_white {
        border-radius: 50%;
        background: $dashboard-primary-theme-text;
        color: #FFF;
        height: 20px;
        width: 20px;
      }
    }
  }

  .personal_texts {
    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      color: #585656;
    }

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 10px;
      line-height: 10px;
      color: #315efb;
    }

    h6 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      color: #315efb;
    }
  }

  .input_section {
    .main_input {
      border-bottom: 0.676897px solid #546781;
      padding-bottom: 4.7px;
      margin-bottom: 21px;

      .input_name {
        padding-right: 17.75px;
        border-right: 0.676897px solid #546781 !important;
        width: 195px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: "Poppins";
        font-size: 12px;
        line-height: 12px;
        color: #585656;

        .red-star {
          color: red;
        }
      }

      .input_widthonly {
        width: 164px !important;
      }

      .input-sec {
        width: 100%;
        display: flex;
        height: 38px;

        select {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 12px;
          color: #585656;
        }

        .ck-powered-by {
          display: none !important;
          background: red;
        }

        .ck-editor__editable {
          min-height: 100px;
        }
      }

      .form-control {
        padding-left: 9px;
        padding-top: 0px;
      }

      .form-control:focus {
        box-shadow: none;
      }

      input {
        align-items: flex-end;
        background: transparent;
        /* border-radius: 0; */
        color: #585656;
        /* display: flex; */
        /* font-family: Poppins; */
        font-size: 12px;
        /* font-weight: 400; */
        /* justify-content: flex-end; */
        /* line-height: 12px; */
      }
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      // color: #315efb;
    }

    .save_btns {
      background: #fdfdfd;
      border: 2px solid #315efb;
      box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(75px);
      border-radius: 20px;
      height: 40px;
      width: 127px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;

      &:hover {
        background: #315efb;
        color: #fdfdfd;
        border: 2px solid #fdfdfd;
      }
    }
  }
}

.paragraph {
  border-right: 0.676897px solid #546781;
}

.select-wrapper {
  flex: 1;

  .select__option {
    background-color: #FFF !important;
    color: $lite-black !important;
  }
}


.select-wrapper .select__option:hover {
  background-color: red;
  opacity: 0.7;
}

.select-wrapper .select__option--is-selected {
  background-color: red;
}