@import '../../../style/_utilities.scss';

.agent-rental {
    font-size: 12px;
    line-height: 12px;
    color: $lite-black;

    .agent-main {
        max-height: 85vh;
        overflow-y: scroll;
    }

    .btn-grp-gap {
        button {
            color: $lite-black;
        }
    }

    .file-number {
        color: $dashboard-primary-theme-text;
    }
}

.type-radio {
    max-width: 150px;
}

.upper-section {
    gap: 22px;
    min-width: 300px;
    flex: 1;
    padding-bottom: 15px;
    border-bottom: 1px solid $dashboard-primary-theme-text;

    .upper-section-left {
        max-width: 52%;
    }

    .upper-divider {
        width: 1px;
        background: $dashboard-primary-theme-text;
    }
}

.inputbox {
    gap: 5px;
    border-bottom: 0.652804px solid #585656;
    padding-bottom: 4px;
    position: relative;
    color: #585656;

    input {
        color: inherit;
    }

    textarea {
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }

    svg {
        display: none;
    }

    .custum-react-select {
        .css-1fdsijx-ValueContainer {
            padding-left: 0px !important;
        }

        .css-1nmdiq5-menu {
            min-width: 30px !important;
        }

        .css-qbdosj-Input {
            max-height: 12px !important;
        }

        // .select__input-container {
        //     position: absolute;
        //     top: -1px;
        //     font-size: 12px;
        //     line-height: 10px;
        // }
    }


    .custom-select {
        /* custom styles for the select component */
        background: red;
    }

    .custom-select__option {
        /* custom styles for the options */
        background: yellow;
    }
}

.inputLable {
    padding-right: 10px !important;
    border-right: 0.652804px solid #585656;
    height: fit-content;
    text-align: end;

    span {
        color: red;
    }

}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: url(./image/calenderIcon.svg) no-repeat;
    cursor: pointer;
}

.file-input {
    flex: 1;
    max-width: 47%;
    min-width: 200px;
    gap: 5.84px;

    input {
        box-shadow: 1px 7px 16px -2px rgba(34, 34, 34, 0.35);
        border-radius: 2px;
        border: 2px solid #FFFFFF;
        background: transparent;
        height: 34px;
        width: 100%;
        font-size: 14px;
        color: $dashboard-primary-theme-text;
    }

    input::-webkit-file-upload-button {
        background: $dashboard-primary-theme-text;
        border: none;
        border-right: 2px solid #FFFFFF;
        font-family: "Poppins" !important;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        height: 100%;
        cursor: pointer;
        width: 40%;
    }

    input::file-selector-button {
        background: $dashboard-primary-theme-text;
        border: none;
        border-right: 2px solid #FFFFFF;
        font-family: "Poppins" !important;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        height: 100%;
        cursor: pointer;
        width: 40%;
    }
}

.second-row {
    .second-row-in {
        padding: 0;
    }
}

.amenities-group {
    gap: 7px;
    padding-left: 17px;

    button {
        background: #FFF;
        // border: 0.5px solid #4E62B1;
        font-family: 'Poppins';
        border-radius: 120.57px;
        font-family: 'Poppins';
        font-weight: 300;
        font-size: 10px;
        line-height: 10px;
        color: #000000;
        padding: 4px 17px;
    }
}

.Amenities {
    // row-gap: 6px;
    // column-gap: 9px;
    gap: 7px;

    .aminity {
        padding: 5px 14px;
        background: #FFFFFF;
        border: 1px solid $dashboard-primary-theme-text;
        border-radius: 120.57px;
    }

    .select-aminity {
        .aminity-input {
            position: absolute;
            z-index: -1;
        }

        input[type="checkbox"]:checked+label {
            background: $dashboard-primary-theme-text;
            color: $white;
            border: 1px solid $white;
        }
    }
}


.btn-grp-gap {
    gap: 4px;

    button {
        font-size: 12px;
    }

    input, select {
        font-size: 9px !important;
        line-height: 10px !important;
        max-width: 96.5px;
        height: 32px;
        background: #FFFFFF;
        border: 1px solid #4E62B1 !important;
        border-radius: 2px;
        padding-left: 4px;
    }
}

.react-datepicker__view-calendar-icon input {
    padding: 0 !important;
}

.react-datepicker__tab-loop {
    font-size: 15px;
}

.rent-inner-section {
    padding: 13px 15px;
    border: 1px solid $dashboard-primary-theme-text;
    box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.35);
    border-radius: 4px;
    font-family: 'Poppins';
    gap: 12px;

    input,
    select {
        font-family: 'Poppins';
        font-size: 12px;
        line-height: 12px;
        color: inherit;
        background-color: transparent;
        border: 0;
    }

    select {

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

}


.radio-btn-bar {
    label {
        font-size: 12px;
        line-height: 12px;
    }

    input[type=radio]:checked~label {
        color: $dashboard-primary-theme-text !important;
    }
}

.add-remove-btn {
    padding: 0;
    margin: 0;
    width: min-content;

    svg {
        font-size: 20px;
    }

    .add-btn {
        color: $dashboard-primary-theme-text;
    }

    .remove-btn {
        color: red;
    }
}

.Leisure-subcat {
    border: 1px solid !important;
}

// .css-1nmdiq5-menu {
//     background: red !important;
//     color: black;
// }
.select__menu-list::-webkit-scrollbar {
    width: 4px;
    height: 0px;
}

.select__menu-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.select__menu-list::-webkit-scrollbar-thumb {
    background: #888;
}

.select__menu-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.area-autocomplete {
    .MuiInput-input {
        padding: 0px !important;
        margin: 0px !important;
    }
}