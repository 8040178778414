@import '../../../../../style/utilities.scss';

.what-wedo-sec {
    color: $homepage-text;
    padding-top: 101px !important;
    // margin-bottom: 145px;
    min-height: 100vh;
    padding-bottom: 140px;

    .what-wedo-heading {
        h1 {
            font-weight: 600;
            font-size: 45px;
            line-height: 45px;
        }

        p {
            font-size: 20px;
            line-height: 20px;
            text-transform: capitalize;
            color: #000000;

        }
    }
}

.what-wedo-sec-inner {
    min-height: 483px;
    background: #EFF3F7;
    border-radius: 60px;
    padding-top: 49px !important;
    gap: 57px;
    color: $homepage-text;

    @media screen and (max-width: 1360px) {
        height: auto;
    }

    .card-container-box {
        top: 195px;
        width: 91%;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 425px) {
            top: 235px;
        }

        .slick-list {
            padding-bottom: 10px;
        }
    }

    .card-container {
        gap: 36px;
        height: 374px;
        // width: 90%;
        // max-width: 1133px;
        overflow-x: scroll;
        scroll-behavior: smooth;
    }
}

.what-card {
    margin: auto;
    width: 255px;
    height: 372px;
    border: 1px solid #EFF3F6;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    gap: 26px;
    padding: 30px;
    background: $white;

    .card-icon-outer {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #EFF3F6;

        .card-icon {
            width: 38px;
            height: 41px;
        }
    }

    h6 {
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
    }

    p {
        font-size: 14px;
        line-height: 23px;
        text-align: center;
    }
}