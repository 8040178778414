@import '../.././.././.././.././style/_utilities.scss';



.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.minute_pagpading {
    padding: 40px 31px;

    .mintit_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #315EFB;
        padding-bottom: 51px;
    }

    .active_btn {
        background: #FFFFFF;
        border: 2px solid #2FC40B;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #2FC40B;
        height: 40px;
        width: 113px;

        &:hover {
            border: 2px solid #FFFFFF;
            background: linear-gradient(0deg, #2FC40B, #2FC40B), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
            color: #FFFFFF;
        }
    }

    .deactivate_btn {
        background: linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        border: 2px solid #FF3838;
        box-shadow: -5.54px 7.03px 22.15px -11.7388px rgba(34, 34, 34, 0.35);
        border-radius: 20px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 11.5729px;
        line-height: 12px;
        color: #FF3838;
        height: 40px;
        width: 113px;

        &:hover {
            border: 2px solid #FFFFFF;
            background: #FF3838;
            color: #FFFFFF;
        }
    }

    .activated_btn {
        border: 2px solid #FFFFFF;
        background: linear-gradient(0deg, #2FC40B, #2FC40B), linear-gradient(157.69deg, rgba(255, 255, 255, 0.1) 6.02%, rgba(255, 255, 255, 0) 95.12%);
        color: #FFFFFF;
    }

    .deactivated_btn {
        border: 2px solid #FFFFFF;
        background: #FF3838;
        color: #FFFFFF;
    }

    .mainweeks_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 39px;

        .anc {
            box-shadow: 5px 5.5px 5.49px rgba(0, 0, 0, 0.3) !important;
        }

        button {
            font-family: 'Poppins';
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            background: #FDFDFD;
            border: 1px solid #315EFB;
            // box-shadow: 13px 16.5px 15.49px -10.86px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(75px);
            border-radius: 4px;
            width: 89px;
            height: 40px;

            &:hover {
                background: $dashboard-primary-theme-text;
                color: $white;
            }
        }
    }

    .mainweeks_wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: initial;
    }

    .main_input {
        border-bottom: 0.676897px solid #546781;
        padding-bottom: 4.7px;
        // margin-bottom: 20px;

        .input_namepad {
            padding-right: 8.75px;
            border-right: 0.676897px solid #546781;
            width: 100px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            color: #585656;
            padding-bottom: 3.4px;

            .red-star {
                color: red !important;
                font-size: 20px;

            }
        }

        .input-sec {
            width: 100%;

            .form-control:focus {
                box-shadow: none;
            }

            input {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #585656;
                border-radius: 0px;
                background: transparent;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

            }
        }


    }

    .addplus {
        height: 23px;
        width: 23px;
    }

    .center {
        .save_btn {
            border: 2px solid #315EFB;
            border-radius: 20px;
            font-family: "Poppins";
            font-size: 12px;
            line-height: 12px;
            color: #315EFB;
            padding: 10px 30px;
        }
    }
}