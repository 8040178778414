@import '../../../../../style/utilities.scss';

.footer {
    // background: #0D141E;
    background: #132f9e;
    min-height: 336px;
    font-family: 'Poppins';
    color: #FFFFFF;
    @media screen and (max-width: 1200px) {
        padding: 20px!important;
    }

    .footer-top {
        min-height: 225px;
        border-bottom: 1px solid #FFFFFF;

        a {
            text-decoration: none;
            color: #FFFFFF;
        }
    }

    .about-us {
        max-width: 296px;

        p {
            font-weight: 400;
            font-size: 12px;
            line-height: 21px;
        }
    }

    .footer-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
    }

    .footer-bottom {
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 18px;
        }
    }
}