@import "../../../style/_utilities.scss";

.message-box {
    max-height: 81vh;
    padding: 14px;
    overflow: hidden;
}

.chat-list {
    gap: 14px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding-right: 10px;

    .chat-person {
        padding: 14px;
        min-width: 333px;
        gap: 21px;
        border-radius: 4px;
        border: 1px solid $dashboard-primary-theme-text;

        &.selected {
            background: rgba($sidebar-primary, .2);
        }
        &.unread {
            background-color: rgba(#ff3838, .2);
        }

        .prrson-name {
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: $repeated-text-color;
            }

            .chat-summery {
                font-size: 12px;
                line-height: 12px;
            }
        }

        .message-time {
            bottom: 9px;
            right: 12px;

            p {
                font-size: 10px;
                line-height: 10px;
                color: $lite-black;
            }


        }
    }

    &::-webkit-scrollbar {
        width: 6.62px;
    }

    &::-webkit-scrollbar-track {

        background: $white;
        border-radius: 94.5483px;
        position: relative;
    }

    &::-webkit-scrollbar-thumb {
        width: 4.73px;
        background: #315EFB;
        border-radius: 94.5483px;
    }
}

.chat-section {
    padding: 0px 20px;
    // max-width: 66%;
    // padding: 0px 36px;
    max-width: 70%;

    .chat-section-header {
        padding-bottom: 10px;
        border-bottom: 1px solid $dashboard-primary-theme-text;

        .prrson-name {
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: $repeated-text-color;
            }

            .chat-summery {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
}

.chat-profile {
    width: 50px;
    height: 50px;
    background: $repeated-text-color;
    border-radius: 50%;
}

.chat-main-box {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 61px;
    bottom: 48px;
    gap: 30px;
    padding: 10px 0px;
    max-height: 62vh;
    // max-height: 72vh;
    overflow-y: scroll;

    .msg-time {
        bottom: -20px;

        p {
            font-size: 10px;
            line-height: 10px;
            color: $lite-black;
        }
    }

    .incoming-msg {
        gap: 12px;

        .msg-text {
            border-radius: 30px 30px 30px 0px;
            padding: 12px 22px;
            background: $repeated-text-color;
            color: $white;
            max-width: 50%;
            width: fit-content;
            min-width: 60px;

            p {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    .outgoing-msg {
        align-self: flex-end;
        gap: 12px;



        .msg-text {
            border-radius: 30px 30px 0px 30px;
            padding: 12px 22px;
            max-width: 50%;
            // width: fit-content;
            min-width: 60px;
            color: $repeated-text-color;
            background: rgba($repeated-text-color, 0.2);

            p {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

.send-msg {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0;
    .emoji-picker-div {
        left: 0;
        bottom: 2px;
        width: 100%;

        .EmojiPickerReact {
            width: 70% !important;
            margin: auto;
        }
    }
    .message-sec {
        .send-button {
            height: 30px;
            min-width: 88px;
        }

        .icon-btn {
            max-width: 10px;
            max-height: 10px;
        }

        .message-box {
            height: 48px;
        }

        .file-select {
            gap: 12px;

            .msgfile {
                transform: rotate(45deg);
            }

            svg {
                color: $dashboard-primary-theme-text;
                width: 16px;
            }
        }
    }

}

.chat-modal-body {


    select {
        font-size: 12px;
        line-height: 12px;
        color: inherit;
    }

    .input_name {
        white-space: nowrap;
    }

    .chat-table {
        thead {
            th {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: $lite-black;
            }
        }

        tbody {
            td {
                font-weight: 300;
                font-size: 12px;
                line-height: 16px;
                color: $lite-black;

                img,
                svg {
                    width: 14px;
                    height: 14px;
                }

                select {
                    padding: 0px;
                    max-width: 150px;
                }
            }
        }


        tbody,
        td,
        tfoot,
        th,
        thead,
        tr {
            border-color: transparent;
            border-style: unset;
            border-width: 0;
        }

        tr {
            border-bottom: 1px solid $dashboard-primary-theme-text;

            td {
                vertical-align: middle;
            }
        }
    }
}

.chat-modal-header {
    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $repeated-text-color;
    }

    .btn-close {
        margin: 0px !important;
    }
}
